;(($, window, document, undefined) => {
    let validCode = true;
    let time = 60;
    $.extend(window.yunu, {
        order_add(){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <div class="layui-form-item">
                        <label class="layui-form-label">所属分组</label>
                        <div class="layui-input-inline w334" id="order_group_input">
                            <input type="hidden" name="group_id" value="0" />
                            <div class="layui-form-mid mr50">无分组</div>
                            <div class="layui-form-mid ml50">
                                <a class="color" href="javascript:;" onclick="yunu.group()">分组管理</a>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">网址</label>
                        <div class="layui-input-inline" style="width: 60px;">
                            <input class="layui-input idis" type="text" placeholder="http(s)" value="http" disabled>
                        </div>
                        <div class="layui-form-mid">://</div>
                        <div class="layui-input-inline" style="width: 60px;">
                            <input class="layui-input idis" type="text" placeholder="www(顶级域名输入*)" value="www" disabled>
                        </div>
                        <div class="layui-form-mid">.</div>
                        <div class="layui-input-inline" style="width: 200px;">
                            <input class="layui-input" type="text" name="domain" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-block mr50">
                            <textarea name="keywords" class="layui-textarea" placeholder="关键词一行一个"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">搜索引擎</label>
                        <div class="layui-input-block">
                            <input type="radio" name="search_engine" value="1" title="百度" checked>
                            <input type="radio" name="search_engine" value="5" title="好搜">
                            <input type="radio" name="search_engine" value="3" title="搜狗">
                            <input type="radio" name="search_engine" value="2" title="百度移动">
                            <input type="radio" name="search_engine" value="6" title="好搜移动">
                            <input type="radio" name="search_engine" value="4" title="搜狗移动">
                            <input type="radio" name="search_engine" value="7" title="神马">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '新增任务',
                    area: ['780px','450px'],
                    content: tpl(),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/yunkuaipai/add.html',
                                field: data.field,
                                success: function (res) {
                                   /* layer.close(index);*/
                                    yunu.msg(res.info);
                                    window.location.href = '/' + module_name + '/yunkuaipai/add_review.html';
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        order_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">所属分组</label>
                        <div class="layui-input-inline w334" id="order_group_input">
                            <input type="hidden" name="group_id" value="${data.group_id}" />
                            <div class="layui-form-mid mr50">${data.group_name}</div>
                            <div class="layui-form-mid ml50">
                                <a class="color" href="javascript:;" onclick="yunu.group()">分组管理</a>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '修改分组',
                    area: ['780px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/yunkuaipai/edit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    obj.update(res.data);
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        group(callback){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '分组管理',
                    area: ['780px', '350px'],
                    content: '<table id="order_group" lay-filter="order_group"></table>',
                    success: function (layero, index) {
                        yunu.table({
                            elem: '#order_group',
                            url: '/' + module_name + '/group/index.html',
                            skin: 'nob',
                            cols: [[
                                {field: 'title', title: '分组', align: 'center', edit: 'text'}
                                ,{title: '操作', align: 'center', templet: function (d) {
                                    if (d.id) {
                                        var el = '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="order_group_check">选用</button>';
                                        el += '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="order_group_edit">修改</button>';
                                        el += '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="order_group_del">删除</button>';
                                    } else {
                                        var el = '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="order_group_add">新增</button>';
                                    }
                                    return el;
                                }}
                            ]],
                            done: function () {
                                yunu.layui_table_redraw('order_group', index);
                            }
                        }, {
                            table: 'order_group',
                            event: {
                                order_group_check: function (data, obj) {
                                    $('#order_group_input').find('input').val(data.id).next().html(data.title);
                                    layer.close(index);
                                    if (callback) {
                                        callback(data.id);
                                    }
                                },
                                order_group_edit: function (data, obj) {
                                    if (typeof(data.title) == "undefined") {
                                        yunu.msg('分组名称不能为空', 'error');
                                        $(obj.tr).find('td').eq(0).click();
                                        return false;
                                    }
                                    yunu.post({
                                        url: '/' + module_name + '/group/edit.html',
                                        field: {
                                            id: data.id,
                                            title: data.title
                                        },
                                        success: function (res) {
                                            yunu.msg(res.info);
                                        }
                                    });
                                },
                                order_group_del: function (data, obj) {
                                    yunu.del({
                                        content: '是否确认删除',
                                        url: '/' + module_name + '/group/del.html',
                                        id: data.id,
                                        success: function (res) {
                                            obj.del();
                                        }
                                    });
                                },
                                order_group_add: function (data, obj) {
                                    if (typeof(data.title) == "undefined") {
                                        yunu.msg('分组名称不能为空', 'error');
                                        $(obj.tr).find('td').eq(0).click();
                                        return false;
                                    }
                                    yunu.post({
                                        url: '/' + module_name + '/group/add.html',
                                        field: {
                                            title: data.title
                                        },
                                        success: function (res) {
                                            yunu.msg(res.info);
                                            table.reload('order_group');
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            });
        },
        package_renew(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号：</label>
                        <div class="layui-form-mid">${data.account}</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">当前套餐：</label>
                        <div class="layui-form-mid">${data.package_name}</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">到期时间：</label>
                        <div class="layui-form-mid">${data.endtime_format}</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">续费金额：</label>
                        <div class="layui-form-mid"><span class="c_30aa44">${data.renew_price}</span> 元</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">备注：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="note">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确认续费</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                yunu.post({
                    url: '/' + module_name + '/yunwanci/get_user_packages.html',
                    field: {id: data.id},
                    success: function (res) {
                        data.renew_price = res['price'];
                        layer.open({
                            type: 1,
                            title: '续费',
                            area: ['631px'],
                            content: tpl(data),
                            success: function (layero, index) {
                                form.render();
                                form.on('submit(*)', function(data){
                                    yunu.post({
                                        url: '/' + module_name + '/yunwanci/renew.html',
                                        field: data.field,
                                        success: function (res) {
                                            layer.close(index);
                                            yunu.msg(res.info, {
                                                end: function () {
                                                    window.location.reload();
                                                }
                                            });
                                        }
                                    });
                                    return false;
                                });
                            }
                        });
                    }
                });
            });
        },
        user_add(agent,oem_domain){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">账号</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="username" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">密码</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="password" name="password" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">QQ</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="qq" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">手机</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input tel" type="text" name="tel" required lay-verify="required">
                    </div>
                </div>
                <!-- <div class="layui-form-item">
                    <label class="layui-form-label">验证码</label>
                    <div class="layui-input-inline w200">
                        <input class="layui-input yzcode" style="width:200px" type="text" name="yzcode" lay-verify="required">
                    </div>
                    <div class="layui-input-inline w150">
                        <input class="layui-btn getyzcode" type="button" onclick="yunu.sendsms($(this))" value="获取验证码">
                    </div>
                </div> -->
                ${agent == '1' ?
                `
                <div class="layui-form-item isagent">
                    <label class="layui-form-label">开代理</label>
                    <div class="layui-input-inline w334">
                        <input type="radio" name="isagent" value="0" title="不允许" checked>
                        <input type="radio" name="isagent" value="1" title="允许" >
                    </div>
                </div>
                ` : ` ` }

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;
                if (oem_domain == "") {
                    layer.msg("未设置代理域名，无法添加子用户", {icon: 2});
                    return false;
                };
                layer.open({
                    type: 1,
                    title: '新增用户',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/user/add.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        user_edittel(){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">手机</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input tel" type="text" name="tel" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">验证码</label>
                    <div class="layui-input-inline w200">
                        <input class="layui-input yzcode" style="width:200px" type="text" name="yzcode" lay-verify="required">
                    </div>
                    <div class="layui-input-inline w150">
                        <input class="layui-btn getyzcode" type="button" onclick="yunu.sendsms($(this))" value="获取验证码">
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '修改手机号',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/user/edittel.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        sendsms(obj){
            let telval = $(".tel").val();
            if (telval == "") {
                layer.msg('请输入手机号', {icon: 2});
                return false;
            }
            if (!telval.match(/^((1[0-9]{10}))$/)) {
                layer.msg('请输入正确手机号', {icon: 2});
                return false;
            }

            let $code = obj;
            if (validCode) {
                yunu.post({
                    url: '/' + module_name + '/login/smscode.html',
                    field: {'tel':telval,'c':1},
                    success: function (res) {
                        if (res.state == 1) {
                            layer.msg(res.info, {icon: 1});
                            validCode = false;
                            let t = setInterval(function  () {
                                time--;
                                $code.val(time+"秒");
                                if (time == 0) {
                                    clearInterval(t);
                                    $code.val("重新获取");
                                    validCode = true;
                                }
                            },1000)
                            if (res.yzcode) {
                                $('[name="yzcode"]').val(res.yzcode)
                            }
                        }else{
                            layer.msg(res.info, {icon: 2});
                        }
                    }
                });
            }
        },
        user_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">账号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input layui-disabled idis" type="text" value="${data.username}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">密码</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="password" name="password">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">QQ</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="qq" value="${data.qq}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">手机</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input layui-disabled idis" type="text" value="${data.tel}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">公司名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="cname" value="${data.cname != null ? data.cname : ''}" >
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">开代理</label>
                        <div class="layui-input-inline w334">
                            ${data['isagent'] == '1' ?
                            `
                            <input type="radio" name="isagent" value="0" title="不允许" >
                            <input type="radio" name="isagent" value="1" title="允许" checked>
                            ` :
                            `
                            <input type="radio" name="isagent" value="0" title="不允许" checked>
                            <input type="radio" name="isagent" value="1" title="允许" >
                            ` }
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">离线发布上级拉取</label>
                        <div class="layui-input-inline w334">
                            <input type="radio" name="offline_release_pid_fetch_state" value="0" title="不拉取" ${data.offline_release_pid_fetch_state == 0 ? 'checked' : ''}>
                            <input type="radio" name="offline_release_pid_fetch_state" value="1" title="拉取" ${data.offline_release_pid_fetch_state == 1 ? 'checked' : ''}>
                        </div>
                    </div>
                    <!-- <div class="layui-form-item">
                        <label class="layui-form-label">快排风控</label>
                        <div class="layui-input-inline w334">
                            ${data['kuaipairisk'] == '1' ?
                            `
                            <input type="radio" name="kuaipairisk" value="0" title="禁用" >
                            <input type="radio" name="kuaipairisk" value="1" title="启用" checked>
                            ` :
                            `
                            <input type="radio" name="kuaipairisk" value="0" title="禁用" checked>
                            <input type="radio" name="kuaipairisk" value="1" title="启用" >
                            ` }
                        </div>
                    </div> -->
                    <div class="layui-form-item">
                        <label class="layui-form-label">大数据线索</label>
                        <div class="layui-input-inline w334">
                            <input type="radio" name="menu_dsjxs" value="1" title="开启" ${data.menu_dsjxs == 1 ? 'checked' : ''}>
                            <input type="radio" name="menu_dsjxs" value="0" title="关闭" ${data.menu_dsjxs == 0 ? 'checked' : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">抖音广告</label>
                        <div class="layui-input-inline w334">
                            <input type="radio" name="menu_douyin_ad" value="1" title="开启" ${data.menu_douyin_ad == 1 ? 'checked' : ''}>
                            <input type="radio" name="menu_douyin_ad" value="0" title="关闭" ${data.menu_douyin_ad == 0 ? 'checked' : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '详细设置',
                    area: ['580px',"540px"],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/user/edit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        product_add(){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">产品名称</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="title" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">分组别名</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="groupname" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">状态</label>
                    <div class="layui-input-inline w334">
                        <input type="radio" name="state" value="0" title="禁用" >
                        <input type="radio" name="state" value="1" title="启用" checked>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">描述</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="remark">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">排序</label>
                    <div class="layui-input-inline w50">
                        <input class="layui-input" type="number" name="sort" value="0'">
                    </div>
                </div>

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '新增产品',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/product/add.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        product_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />

                    <div class="layui-form-item">
                        <label class="layui-form-label">产品名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="title" value="${data.title}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">分组别名</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="groupname" value="${data.groupname}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">状态</label>
                        <div class="layui-input-inline w334">
                            ${data['state'] == '1' ?
                            `
                            <input type="radio" name="state" value="0" title="禁用" >
                            <input type="radio" name="state" value="1" title="启用" checked>
                            ` :
                            `
                            <input type="radio" name="state" value="0" title="禁用" checked>
                            <input type="radio" name="state" value="1" title="启用" >
                            ` }
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">描述</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" value="${data.remark}" name="remark">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">排序</label>
                        <div class="layui-input-inline w50">
                            <input class="layui-input" type="number" value="${data.sort}" name="sort" value="0'">
                        </div>
                    </div>

                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '编辑产品',
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/product/edit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        setmeal_add(pid,ptitle){
            let tpl = `<form class="layui-form pt25 pb20">
                <input type="hidden" name="pid" value="${pid}">
                <div class="layui-form-item">
                    <label class="layui-form-label">产品名称</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input layui-disabled" type="text" value="${ptitle}" disabled>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐名称</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="title" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐价格</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="price" placeholder="￥" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">状态</label>
                    <div class="layui-input-inline w334">
                        <input type="radio" name="state" value="0" title="禁用" >
                        <input type="radio" name="state" value="1" title="启用" checked>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">描述</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="remark">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">排序</label>
                    <div class="layui-input-inline w50">
                        <input class="layui-input" type="number" name="sort" value="0'">
                    </div>
                </div>

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '新增套餐',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/product/setmealadd.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        setmeal_edit(data, obj){
            let tpl = `<form class="layui-form pt25 pb20">
                <input type="hidden" name="id" value="${data.id}" />
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐名称</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="title" value="${data.title}" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐价格</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="price" value="${data.price}" placeholder="￥" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">状态</label>
                    <div class="layui-input-inline w334">
                        ${data['state'] == '1' ?
                        `
                        <input type="radio" name="state" value="0" title="禁用" >
                        <input type="radio" name="state" value="1" title="启用" checked>
                        ` :
                        `
                        <input type="radio" name="state" value="0" title="禁用" checked>
                        <input type="radio" name="state" value="1" title="启用" >
                        ` }
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">描述</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" value="${data.remark}" name="remark">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">排序</label>
                    <div class="layui-input-inline w50">
                        <input class="layui-input" type="number" value="${data.sort}" name="sort" value="0'">
                    </div>
                </div>

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '编辑套餐',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/product/setmealedit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        recharge(data){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="uid" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input layui-disabled" type="text" value="${data.username}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">充值</label>
                        <div class="layui-input-block">
                            <input type="radio" name="type" value="0" title="入款" checked>
                            <input type="radio" name="type" value="1" title="扣款">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">金额</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="number" name="money" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">备注</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="note">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '充值',
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/user/recharge.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        package_price(data){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;
                let url = '/' + module_name + '/user/package_price.html';
                yunu.get({
                    url: url,
                    field: {id: data.id},
                    success: function (res) {
                        layer.open({
                            type: 1,
                            title: '修改价格',
                            area: ['500px', '620px'],
                            content: res.tpl,
                            success: function (layero, index) {
                                let package_field = {}
                                table.init('package_price');
                                table.on('edit(package_price)', function(obj){
                                    if (obj.value) {
                                        if (obj.value.match(/^([1-9]\d*|[0]{1})(\.\d{1,2})?$/) == null) {
                                            yunu.msg('请输入正确金额', 'error');
                                            return false;
                                        }
                                    };
                                    console.log(obj);
                                    var param = {
                                        'sid': obj['data']['sid'],
                                        'price': obj.value,
                                        'uid': data.id,
                                    }
                                    $.post(url, param, function (res) {
                                        yunu.msg(res.info || '错误', res.state == 1 ? null : 'error');
                                    });
                                });
                            }
                        });
                    }
                });
            });
        },
        admin_add(){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">账号</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="username" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">密码</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="password" name="password" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '新增管理员',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/admin/add.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        admin_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">账号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input layui-disabled" type="text" value="${data.username}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">新密码</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="password" name="password">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '编辑管理员',
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/admin/edit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        review(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" value="${data.account != null ? data.account : ''}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">密码：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" value="${data.password != null ? data.password : ''}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">联系方式：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.tel}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司名称：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.cname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">法人代表：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.legal_name}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业分类：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.type}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司地址：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.address}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">官网Url：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.domain_url}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline photo_box">
                            <label class="layui-form-label">公司logo：</label>
                            <div class="layui-input-inline">
                                <img layer-src="${data.logo}" src="${data.logo}" width="180px" height="150px">
                            </div>
                        </div>
                        <div class="layui-inline photo_box">
                            <label class="layui-form-label">营业执照：</label>
                            <div class="layui-input-inline">
                                <img layer-src="${data.license}" src="${data.license}" width="180px" height="150px">
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业关键词：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.keywords}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">备注：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.remark != null ? data.remark : ''}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">购买套餐：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.setmealtitle}</div>
                            </div>
                        </div>
                    </div>
                    ${data['state'] != 1 ?
                    `<div class="layui-form-item">
                         <label class="layui-form-label">审核：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="state" value="1" title="通过" ${data.state == 1 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                             <input type="radio" name="state" value="2" title="不通过" ${data.state == 2 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                         </div>
                     </div>` : ''}
                    <div class="layui-form-item" id="review_note" style="display: none;">
                        <label class="layui-form-label"></label>
                        <div class="layui-input-block mr50">
                            <textarea name="review_note" class="layui-textarea"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        layer.photos({
                            photos: '.photos'
                        });
                        form.on('radio(state)', function(data){
                            data.value == 2 ? $('#review_note').show() : $('#review_note').hide();
                        });
                        form.on('submit(*)', function(data){
                            if (!data.field.state) {
                                yunu.msg('请审核', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/douyin/review.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                    $(obj.tr).find('.table_btn').remove();
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        reviewkey(data, obj, callback){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 pr20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">网址：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" name="domain" value="http://www.${data.domain}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">熊掌号：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" name="xzh" value="${data.xzh != null ? data.xzh : ''}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" name="keyword" value="${data.keyword}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">添加时间：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" value="${data.add_time_format}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">百度指数：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" value="${data.baidu_index}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">初排：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" value="${data.initial_rank}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">价格：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="price" value="${data.price}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                         <label class="layui-form-label">审核：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="state" value="1" title="通过" required lay-verify="required" checked>
                             <input type="radio" name="state" value="2" title="删除" required lay-verify="required">
                         </div>
                     </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: [],
                    content: tpl({
                        ...data
                    }),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            if (!data.field.state) {
                                yunu.msg('请审核', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/yunkuaipai/ok.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            if (typeof callback == 'function') {
                                                callback(res);
                                            } else {
                                                window.location.reload();
                                            }
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });

            });
        },
        order_price_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">价格</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="price" value="${data.price}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '批量调价',
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/yunkuaipai/editprice.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(res.data);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        sync(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="account" value="${data.account}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">密码：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="password" value="${data.password}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">联系方式：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.tel}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司名称：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.cname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">法人代表：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.legal_name}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业分类：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.type}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司地址：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.address}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">官网Url：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.domain_url}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline photo_box">
                            <label class="layui-form-label">公司logo：</label>
                            <div class="layui-input-inline">
                                <img layer-src="${data.logo}" src="${data.logo}">
                            </div>
                        </div>
                        <div class="layui-inline photo_box">
                            <label class="layui-form-label">营业指摘：</label>
                            <div class="layui-input-inline">
                                <img layer-src="${data.license}" src="${data.license}">
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业关键词：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.keywords}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">备注：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.note}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">购买套餐：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.package_name}</div>
                                <input type="hidden" name="package_name" value="${data.package_name}" />
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">参与活动：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.activity_name != null ? data.activity_name : '无'}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                         <label class="layui-form-label">套餐：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="comboId" value="13" title="模板月度" ${data.package_id == 1 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="10" title="模板季度" ${data.package_id == 2 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="11" title="模板半年" ${data.package_id == 3 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="12" title="模板年度" ${data.package_id == 4 ? 'checked' : ''}>
                             <br>
                             <input type="radio" name="comboId" value="15" title="官网月度" ${data.package_id == 5 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="4" title="官网季度" ${data.package_id == 6 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="5" title="官网半年" ${data.package_id == 7 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="6" title="官网年度" ${data.package_id == 8 ? 'checked' : ''}>
                         </div>
                     </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                            <button type="reset" class="layui-btn layui-btn-primary">取消</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        layer.photos({
                            photos: '.photos'
                        });
                        form.on('submit(*)', function(data){
                            if (!data.field.comboId) {
                                yunu.msg('请选择套餐', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/Review/sync',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        packageshow(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">公司名称：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.cname}</div>
                            </div>
                        </div>
                    </div>
                    ${data['proname'] != '其他类型' ?
                    `
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">建站产品：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.proname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">产品类型：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.protype}</div>
                            </div>
                        </div>
                    </div>
                        ${data['proname'] == '模板网站' ?
                        `
                        <div class="layui-form-item">
                            <div class="layui-inline">
                                <label class="layui-form-label">模板编号：</label>
                                <div class="layui-input-inline">
                                    <div class="layui-form-mid">${data.template_number}</div>
                                </div>
                            </div>
                        </div>
                        ` : ''}
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">空间：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.fujia1}</div>
                            </div>
                        </div>

                         ${data['proname'] == 'CMS建站' ?
                        `
                        <div class="layui-inline">
                            <label class="layui-form-label">域名：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.domain}</div>
                            </div>
                        </div>
                        `:`
                        <div class="layui-inline">
                            <label class="layui-form-label">小程序：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.fujia2}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">英文版：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.fujia3}</div>
                            </div>
                        </div>
                        `}
                    </div>

                    ` : `
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">建站产品：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.proname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label"></label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid"></div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label"></label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid"></div>
                            </div>
                        </div>
                    </div>

                    `}
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">订单描述：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.content}</div>
                            </div>
                        </div>
                    </div>

                    <div class="layui-form-item">
                         <label class="layui-form-label">制作状态：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="makestate" value="1" title="已交付" ${data.makestate == 1 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                             <input type="radio" name="makestate" value="2" title="制作中" ${data.makestate == 2 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                         </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '查看',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        layer.photos({
                            photos: '.photos'
                        });
                        form.on('submit(*)', function(data){
                            if (!data.field.makestate) {
                                yunu.msg('请选择订单状态', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/Package/review',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        activity_add(){
            let tpl = `<form class="layui-form pt25 pb20" lay-filter="form">
                <div class="form_group_box"></div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确认添加</button>
                        <a class="color ml20 add_items" href="javascript:;">增加活动</a>
                    </div>
                </div>
            </form>`;

            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;
                let laydate = layui.laydate;

                let add_items = function () {
                    let index = $('.form_group_box .layui-form-item').length;
                    $('.form_group_box').append(`
                        <div class="form_group_item">
                            <div class="layui-form-item">
                                <label class="layui-form-label">活动名称：</label>
                                <div class="layui-input-inline w334">
                                    <input class="layui-input" type="text" name="name[]" required lay-verify="required">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">活动时间：</label>
                                <div class="layui-input-inline w334">
                                    <input type="text" class="layui-input" id="date_range_${index}" name="date_range[]" required lay-verify="required">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">选择套餐：</label>
                                <div class="layui-input-inline w334">
                                    <select name="package_id[]" xm-select="package_id_${index}" xm-select-search="" xm-select-radio required lay-verify="required">
                                        <option value="">请选择</option>
                                    </select>
                                </div>
                            </div>
                            <div class="layui-form-item" lay-filter="form_select2_${index}">
                                <label class="layui-form-label">购买年限：</label>
                                <div class="layui-input-inline w334">
                                    <select name="year[]" required lay-verify="required">
                                        <option value="1">一年</option>
                                        <option value="2">二年</option>
                                        <option value="3">三年</option>
                                    </select>
                                </div>
                            </div>
                            <div class="layui-form-item" lay-filter="form_select_${index}">
                                <label class="layui-form-label">赠送时长：</label>
                                <div class="layui-input-inline w334">
                                    <select name="gift_month[]" required lay-verify="required">
                                        <option value="1">送1个月</option>
                                        <option value="3">送3个月</option>
                                        <option value="6">送6个月</option>
                                        <option value="12">送12个月</option>
                                        <option value="24">送24个月</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    `);
                    layui.formSelects.data('package_id_'+index, 'server', {
                        url: '/' + module_name + '/Api/get_package'
                    });
                    laydate.render({
                        elem: '#date_range_'+index
                        ,type: 'datetime'
                        ,range: true
                    });
                    form.render('select', 'form"] .layui-form-item[lay-filter="form_select_'+index);
                    form.render('select', 'form"] .layui-form-item[lay-filter="form_select2_'+index);
                }

                layer.open({
                    type: 1,
                    title: '添加',
                    area: ['631px', '60%'],
                    content: tpl,
                    success: function (layero, index) {
                        add_items();
                        $('.add_items').on('click', add_items);
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Activity/add',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        activity_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20" lay-filter="form">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">活动名称：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="name" value="${data.name}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">活动时间：</label>
                        <div class="layui-input-inline w334">
                            <input type="text" class="layui-input" id="date_range" name="date_range" value="${data.starttime+' - '+ data.endtime}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">选择套餐：</label>
                        <div class="layui-input-inline w334">
                            <select name="package_id" xm-select="package_id" xm-select-search="" xm-select-radio required lay-verify="required">
                                <option value="">请选择</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item" lay-filter="form_select2">
                        <label class="layui-form-label">购买年限：</label>
                        <div class="layui-input-inline w334">
                            <select name="year[]" required lay-verify="required">
                                <option value="1" ${data.year == 1 ? 'selected' : ''}>一年</option>
                                <option value="2" ${data.year == 2 ? 'selected' : ''}>二年</option>
                                <option value="3" ${data.year == 3 ? 'selected' : ''}>三年</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item" lay-filter="form_select">
                        <label class="layui-form-label">赠送时长：</label>
                        <div class="layui-input-inline w334">
                            <select name="gift_month" required lay-verify="required">
                                <option value="1" ${data.gift_month == 1 ? 'selected' : ''}>送1个月</option>
                                <option value="3" ${data.gift_month == 3 ? 'selected' : ''}>送3个月</option>
                                <option value="6" ${data.gift_month == 6 ? 'selected' : ''}>送6个月</option>
                                <option value="12" ${data.gift_month == 12 ? 'selected' : ''}>送12个月</option>
                                <option value="24" ${data.gift_month == 24 ? 'selected' : ''}>送24个月</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确认</button>
                        </div>
                    </div>
                </form>`
            };

            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;
                let laydate = layui.laydate;

                layer.open({
                    skin: 'no_scroll',
                    type: 1,
                    title: '添加',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        formSelects.config('package_id', {
                            beforeSuccess: function(id, url, searchVal, result){
                                for (var i = 0; i < result.data.length; i++) {
                                    if (result.data[i]['value'] == data.package_id) {
                                        result.data[i]['selected'] = 'selected';
                                    }
                                }
                                return result;
                            },
                        });
                        layui.formSelects.data('package_id', 'server', {
                            url: '/' + module_name + '/Api/get_package'
                        });
                        laydate.render({
                            elem: '#date_range'
                            ,type: 'datetime'
                            ,range: true
                        });
                        form.render();
                        form.on('submit(*)', function(data){
                            let [starttime, endtime] = data.field.date_range.split(' - ');
                            data.field.starttime = starttime;
                            data.field.endtime = endtime;
                            yunu.post({
                                url: '/' + module_name + '/Activity/edit',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        reviewDouyin(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="account" value="${data.account}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">密码：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="password" value="${data.password}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">联系方式：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.tel}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司名称：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.cname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">法人代表：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.legal_name}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业分类：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.type}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司地址：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.address}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline photo_box">
                            <label class="layui-form-label">营业执照：</label>
                            <div class="layui-input-inline">
                                <img layer-src="${data.license}" src="${data.license}" width="180px" height="150px">
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业关键词：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.keywords}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">备注：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.remark}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">购买套餐：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.package_name}</div>
                            </div>
                        </div>
                    </div>
                    ${data['state'] != 1 ?
                    `<div class="layui-form-item">
                         <label class="layui-form-label">审核：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="state" value="1" title="通过" ${data.state == 1 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                             <input type="radio" name="state" value="2" title="不通过" ${data.state == 2 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                         </div>
                     </div>` : ''}
                    <div class="layui-form-item" id="review_note" style="display: none;">
                        <label class="layui-form-label"></label>
                        <div class="layui-input-block mr50">
                            <textarea name="review_note" class="layui-textarea"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        layer.photos({
                            photos: '.photos'
                        });
                        form.on('radio(state)', function(data){
                            data.value == 2 ? $('#review_note').show() : $('#review_note').hide();
                        });
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Douyin/review.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                    $(obj.tr).find('.table_btn').remove();
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        cluewords_relation(data, obj, api_url){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '关键词',
                    area: ['1200px', '60%'],
                    content: `<div class="pd20">
                        <div class="top-tip mb20">
                            列表只出现已审核的关键词，代理商可以根据需求自行维护客户数据，每个客户每天最多手动采集5次，请认真核对数据后再进行提交<a href="https://docs.qq.com/doc/DUmVtVWt5R1lqaHhK" target="_blank">【查看教程】</a>
                        </div>
                        <div class="table_mult">
                            <div class="table_cluewords_relation" id="table_cluewords_relation" lay-filter="table_cluewords_relation"></div>
                        </div>
                    </div>`,
                    success: function (layero, index) {
                        yunu.table({
                            elem: '#table_cluewords_relation',
                            skin: 'mult',
                            url: api_url,
                            where: {id: data.id},
                            limits: [10, 20, 50, 100, 200],
                            cols: [[
                                {field: 'index', title: '序号', type: 'numbers', width:60, sort: true}
                                ,{field: 'keyword', title: '关键词', align: 'center'}
                                ,{field: 'review_state', title: '审核状态', align: 'center', templet: function (d) {
                                    if (d.review_state == 1) {
                                        return '已审核'
                                    }
                                    return '待审核';
                                }}
                                ,{title: '操作', align: 'center', minWidth: 400, templet: function (d) {
                                    return '<a class="table_btn" href="javascript:;" lay-event="douyin_single">单采</a>';
                                }}
                            ]],
                        }, {
                            table: 'table_cluewords_relation',
                            search: false,
                            qsave: '/' + module_name + '/Douyin/cluewords_relation_qsave',
                            switch: ['state', 'precise_mode'],
                            event: {
                                douyin_single: function (data, obj) {
                                    yunu.douyin_single(data, obj);
                                },
                            }
                        });
                    }
                })
            });
        },
        douyin_single(data, obj){
            let tpl = `<form class="layui-form pt25 pb20">
                <input type="hidden" name="rid" value="${data.id}" />
                <div class="layui-form-item">
                    <label class="layui-form-label">视频id</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="aweme_id" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">命中关键词</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="keywords" required lay-verify="required" value="多少钱|价格|方案|联系|电话|怎么买|怎么卖|合作|购买|地址|在哪里|多少米|哪买的|有兴趣了解">
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '单个视频采集',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Douyin/single',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    layer.alert(res.info);
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        recharge_count(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">产品</label>
                        <div class="layui-input-block">
                            <input type="radio" name="recharge_type" value="0" title="品牌投流" lay-filter="recharge_type" checked>
                            <input type="radio" name="recharge_type" value="1" title="转化投流" lay-filter="recharge_type">
                            <input type="radio" name="recharge_type" value="2" title="按天计费充值" lay-filter="recharge_type">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">类型</label>
                        <div class="layui-input-block">
                            <input type="radio" name="type" value="0" title="充值" checked>
                            <input type="radio" name="type" value="1" title="扣款">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">金额</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="number" name="recharge_count" required lay-verify="required">
                        </div>
                        <div class="layui-form-mid" id="recharge_count_unit">次</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">备注</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="remark">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '投流充值',
                    area: ['880px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('radio(recharge_type)', function(data){
                            let text = data.value == 0 ? '次' : '元';
                            $('#recharge_count_unit').html(text)
                        });
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Douyin/recharge_count.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        qcy_config(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">广告主ID</label>
                        <div class="layui-input-inline w334">
                            <input type="text" name="advertiser_id" class="layui-input" value="${data.advertiser_id}" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                         <label class="layui-form-label">广告类型</label>
                         <div class="layui-input-block">
                             <input type="radio" name="ad_type" lay-filter="ad_type" value="1" title="广告计划" ${data.ad_type == 1 ? 'checked' : ''} required lay-verify="required">
                             <input type="radio" name="ad_type" lay-filter="ad_type" value="2" title="广告组" ${data.ad_type == 2 ? 'checked' : ''} required lay-verify="required">
                             <input type="radio" name="ad_type" lay-filter="ad_type" value="3" title="广告主" ${data.ad_type == 3 ? 'checked' : ''} required lay-verify="required">
                         </div>
                     </div>
                    <div class="layui-form-item ad_id" ${data.ad_type != 1 ? 'style="display: none;"' : ''}>
                        <label class="layui-form-label">广告计划ID</label>
                        <div class="layui-input-inline w334">
                            <textarea name="ad_id" class="layui-textarea">${data.ad_id}</textarea>
                        </div>
                    </div>
                    <div class="layui-form-item ad_zid" ${data.ad_type != 2 ? 'style="display: none;"' : ''}>
                        <label class="layui-form-label">广告组ID</label>
                        <div class="layui-input-inline w334">
                            <textarea name="ad_zid" class="layui-textarea">${data.ad_zid}</textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">飞鱼子用户ID</label>
                        <div class="layui-input-inline w334">
                            <input type="text" name="clue_account_id" class="layui-input" value="${data.clue_account_id}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">线索所属人</label>
                        <div class="layui-input-inline w334">
                            <input type="text" name="clue_ssr" class="layui-input" value="${data.clue_ssr}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系人姓名</label>
                        <div class="layui-input-inline w334">
                            <input type="text" name="tf_telname" class="layui-input" value="${data.tf_telname}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系人电话</label>
                        <div class="layui-input-inline w334">
                            <input type="text" name="tf_tel" class="layui-input" value="${data.tf_tel}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">余额关闭计划</label>
                        <div class="layui-input-block">
                            <input type="radio" name="money_close" value="1" title="开启" ${data.money_close == 1 ? 'checked' : ''} required lay-verify="required">
                            <input type="radio" name="money_close" value="0" title="关闭" ${data.money_close == 0 ? 'checked' : ''} required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">线索明文</label>
                        <div class="layui-input-block">
                            <input type="radio" name="clue_showtel" value="1" title="隐藏" ${data.clue_showtel == 1 ? 'checked' : ''} required lay-verify="required">
                            <input type="radio" name="clue_showtel" value="0" title="显示" ${data.clue_showtel == 0 ? 'checked' : ''} required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">订单状态</label>
                        <div class="layui-input-block">
                            <input type="radio" name="runtype" lay-filter="runtype"  value="1" title="正常" ${data.runtype == 1 ? 'checked' : ''} required lay-verify="required">
                            <input type="radio" name="runtype" lay-filter="runtype"  value="0" title="停止" ${data.runtype == 0 ? 'checked' : ''} required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">oCPM模式</label>
                        <div class="layui-input-block">
                            <input type="radio" name="ocpmstatus" value="1" title="开启" ${data.ocpmstatus == 1 ? 'checked' : ''} required lay-verify="required">
                            <input type="radio" name="ocpmstatus" value="0" title="关闭" ${data.ocpmstatus == 0 ? 'checked' : ''} required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                            <a class="layui-btn btn" href="javascript:;" id="tongbutel">同步</a>
                            <a class="layui-btn btn" href="javascript:;" id="buqissr">补齐所属人</a>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let laydate = layui.laydate;

                layer.open({
                    type: 1,
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('radio(ad_type)', function(data){
                            if (data.value == 1) {
                                $('.ad_id').show();
                                $('.ad_zid').hide();
                            }
                            if (data.value == 2) {
                                $('.ad_id').hide();
                                $('.ad_zid').show();
                            }
                            if (data.value == 3) {
                                $('.ad_id').hide();
                                $('.ad_zid').hide();
                            }
                        });
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Ajax/qcy_config.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                        $('#tongbutel').on('click', function () {
                            yunu.post({
                                url: '/' + module_name + '/Ajax/qcy_tongbutel.html',
                                field: {id: data.id},
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                        $('#buqissr').on('click', function () {
                            yunu.post({
                                url: '/' + module_name + '/Ajax/qcy_buqissr.html',
                                field: {id: data.id},
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        tongbu_enterpriseLeadsUserListGet(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">同步类型</label>
                        <div class="layui-input-block">
                            <input type="radio" name="type" value="0" title="意向用户" lay-filter="type" checked>
                            <input type="radio" name="type" value="1" title="视频数据" lay-filter="type" >
                            <input type="radio" name="type" value="2" title="粉丝数据" lay-filter="type" >
                            <input type="radio" name="type" value="3" title="互动记录" lay-filter="type" >
                        </div>
                    </div>
                    <div class="layui-form-item type1_item">
                        <label class="layui-form-label">开始时间</label>
                        <div class="layui-input-inline w334">
                            <input type="text" name="start_time" id="start_time2" class="layui-input" value="" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item type1_item">
                        <label class="layui-form-label">结束时间</label>
                        <div class="layui-input-inline w334">
                            <input type="text" name="end_time" id="end_time2" class="layui-input" value="" lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">开始同步</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let laydate = layui.laydate;

                layer.open({
                    type: 1,
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.on('radio(type)', function(data){
                            data.value == 0 || data.value == 3 ? $('.type1_item').show() : $('.type1_item').hide();
                        });
                        let start = new Date();
                        start.setDate(start.getDate() - 1)
                        laydate.render({
                            elem: '#start_time2'
                            ,type: 'datetime'
                            ,btns: ['clear', 'confirm']
                            ,min: '2022-01-01 00:00:00'
                             ,value: start
                        });
                        laydate.render({
                            elem: '#end_time2'
                            ,type: 'datetime'
                            ,min: '2022-01-01 00:00:00'
                             ,value: new Date()
                        });
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/douyin/tongbu_enterpriseLeadsUserListGet.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            //obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        atjf_keyword_table(data, obj){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '按天计费',
                    area: ['1280px'],
                    content: `<div class="pd15">
                        <button type="button" class="layui-btn" id="atjf_keyword_add">新增</button>
                        <button type="button" class="layui-btn" id="atjf_keyword_batch_edit">批量修改</button>
                        <button type="button" class="layui-btn" id="atjf_keyword_batch_del">批量删除</button>
                        <button type="button" class="layui-btn" id="atjf_keyword_history">消费记录</button>
                        <button type="button" class="layui-btn" id="atjf_keyword_correct">修正数据</button>
                        <div class="search-form">
                            <div class="layui-inline">
                                <div class="layui-input-inline">
                                    <input type="text" class="layui-input" name="newsearch" id="newsearch" placeholder="关键词">
                                </div>
                            </div>
                            <button class="layui-btn sub_btn" name="new_search_btn" id="new_search_btn" type="button">搜索</button>
                        </div>
                        <table id="atjf" lay-filter="atjf"></table>
                    </div>`,
                    success: function (layero, index) {
                        var newtable = yunu.table({
                            elem: '#atjf',
                            url: '/' + module_name + '/Douyin/atjf_keyword_table.html',
                            where: {id: data.id},
                            skin: 'nob',
                            height: 550,
                            cols: [[
                                {type:'checkbox'}
                                ,{field: 'index', title: '序号', type: 'numbers', width:60, sort: true}
                                ,{field: 'keyword', title: '关键词', align: 'center'}
                                ,{field: 'name', title: '蓝v名称', align: 'center'}
                                ,{field: 'money', title: '单价/天/元', align: 'center'}
                                ,{field: 'state', title: '状态', align: 'center', templet: function (d) {
                                    return '<div><input type="checkbox" name="state" lay-skin="switch" lay-filter="state" '+(d.state == 1 ? 'checked': '')+'></div>';
                                }}
                                ,{field: 'auto_charging', title: '自动扣费', align: 'center', templet: function (d) {
                                    return '<div><input type="checkbox" name="auto_charging" lay-skin="switch" lay-filter="auto_charging" '+(d.auto_charging == 1 ? 'checked': '')+'></div>';
                                }}
                                ,{field: 'create_time', title: '创建时间', align: 'center'}
                                ,{title: '操作', align: 'center', templet: function (d) {
                                    var el = '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="atjf_edit">修改</button>';
                                    el += '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="atjf_keyword_del">删除</button>';
                                    el += '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="atjf_keyword_deduction">扣款</button>';
                                    return el;
                                }}
                            ]],
                            done: function (res, curr, count) {
                                $.each(res.data, function (i, v) {
                                    $('[data-index="'+i+'"]').attr('data-id', v.id);
                                });
                                yunu.layui_table_redraw('atjf', index, 66);
                            }
                        }, {
                            table: 'atjf',
                            search: false,
                            qsave: '/' + module_name + '/Douyin/atjf_keyword_qsave',
                            switch: ['state', 'auto_charging'],

                            event: {
                                atjf_edit: function (data, obj) {
                                    yunu.atjf_keyword_edit(data, obj)
                                },
                                atjf_keyword_del: function (data, obj) {
                                    yunu.del({
                                        content: '您确认删除吗',
                                        url: '/' + module_name + '/douyin/atjf_keyword_del.html',
                                        id: data.id,
                                        success: function (res) {
                                            obj.del();
                                        }
                                    });
                                },
                                atjf_keyword_deduction: function (data, obj) {
                                    yunu.atjf_keyword_deduction(data)
                                },
                            }
                        });

                        $('#new_search_btn').on('click', function () {
                            table.reload('atjf', {
                                page: {
                                    curr: 1
                                },
                                where: {'keyname':$("#newsearch").val()}
                            });
                        });




                        $('#atjf_keyword_add').on('click', function () {
                            yunu.atjf_keyword_add(data.id)
                        });
                        $('#atjf_keyword_history').on('click', function () {
                            yunu.atjf_keyword_history(data.id)
                        });

                        $('#atjf_keyword_batch_edit').on('click', function () {
                            var checkStatus = table.checkStatus('atjf');
                            if (checkStatus.data.length) {
                                var ids = checkStatus.data.map(function (v) {
                                    return v.id;
                                }).join(',');

                                layer.prompt({
                                    value: '',
                                    title: '蓝v名称'
                                }, function(value, index, elem){
                                    yunu.post({
                                        url: '/' + module_name + '/douyin/atjf_keyword_batch_edit',
                                        field: {
                                            id: ids,
                                            name: value
                                        },
                                        success: function (res) {
                                            layer.close(index);
                                            yunu.msg(res.info);
                                            table.reload('atjf');
                                        }
                                    });
                                });
                            } else {
                                yunu.msg('请选中', 'error');
                            }
                        });

                        $('#atjf_keyword_batch_del').on('click', function () {
                            var checkStatus = table.checkStatus('atjf');
                            if (checkStatus.data.length) {
                                var ids = checkStatus.data.map(function (v) {
                                    return v.id;
                                }).join(',');

                                layer.confirm('您确认删除吗', {icon: 3, title:'提示'}, function(index){
                                    layer.close(index);
                                    yunu.post({
                                        url: '/' + module_name + '/douyin/atjf_keyword_batch_del',
                                        field: {id: ids},
                                        success: function (res) {
                                            yunu.msg(res.info);
                                            table.reload('atjf');
                                        }
                                    });
                                });
                            } else {
                                yunu.msg('请选中', 'error');
                            }
                        });

                        $('#atjf_keyword_correct').on('click', function () {
                            yunu.post({
                                url: '/' + module_name + '/douyin/atjf_keyword_correct.html',
                                field: {
                                    id: data.id
                                },
                                success: function (res) {
                                    yunu.msg(res.info);
                                }
                            });
                        });
                    }
                });
            });
        },
        atjf_keyword_history(dataid){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '消费记录',
                    area: ['900px'],
                    content: `<div class="pd15">
                        <table id="history" lay-filter="history"></table>
                    </div>`,
                    success: function (layero, index) {
                        yunu.table({
                            elem: '#history',
                            url: '/' + module_name + '/Douyin/atjf_keyword_history.html',
                            where: {id: dataid},
                            skin: 'nob',
                            height: 550,
                            cols: [[
                                {type:'checkbox'}
                                ,{field: 'index', title: '序号', type: 'numbers', width:60, sort: true}
                                ,{field: 'keystr', title: '关键词', align: 'center'}
                                ,{field: 'rank', title: '当日排名', align: 'center'}
                                ,{field: 'money', title: '扣款金额', align: 'center'}
                                ,{field: 'create_time', title: '检测时间',width:190, align: 'center'}
                                ,{title: '操作', align: 'center', width:60,templet: function (d) {
                                    var el = '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="atjf_keyword_history_del">删除</button>';
                                    return el;
                                }}
                            ]],
                            done: function (res, curr, count) {
                                $.each(res.data, function (i, v) {
                                    $('[data-index="'+i+'"]').attr('data-id', v.id);
                                });
                                yunu.layui_table_redraw('atjf', index, 66);
                            }
                        }, {
                            table: 'history',
                            switch: ['state'],
                            event: {
                                atjf_keyword_history_del: function (data, obj) {
                                    yunu.del({
                                        content: '您确定纠错吗，提交后将删除此条扣款记录！',
                                        url: '/' + module_name + '/douyin/atjf_keyword_history_del.html',
                                        id: data.id,
                                        success: function (res) {
                                            obj.del();
                                        }
                                    });
                                },
                            }
                        });
                    }
                });
            });
        },
        atjf_keyword_deduction(newdata){
            let tpl = (newdata = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="did" value="${newdata.did}">
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="keyword" disabled lay-verify="required" value="${newdata.keyword}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">单价</label>
                        <div class="layui-input-inline w334">
                        <input class="layui-input" type="number" name="money" disabled lay-verify="required" value="${newdata.money}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">当前排名</label>
                        <div class="layui-input-inline w334">
                        <input class="layui-input" type="number" name="rank" required lay-verify="required" value="1">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">扣款日期</label>
                        <div class="layui-input-inline w334">
                            <input type="text" name="start_time" id="start_time" class="layui-input" value="" lay-verify="required">
                        </div>
                    </div>

                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form', 'table', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;
                let laydate = layui.laydate;
                layer.open({
                    type: 1,
                    title: '新增扣款',
                    area: ['640px'],
                    content: tpl(newdata),
                    success: function (layero, index) {
                        form.render();
                        let start = new Date();
                        start.setDate(start.getDate() - 1)
                        laydate.render({
                            elem: '#start_time'
                            ,type: 'datetime'
                            ,btns: ['clear', 'confirm']
                            ,min: '2022-01-01 00:00:00'
                            ,max: start.toISOString().slice(0, 19).replace('T',' ')
                            ,value: start
                        });

                        form.on('submit(*)', function(data){
                            data.field.kid = newdata.id
                            yunu.post({
                                url: '/' + module_name + '/Douyin/atjf_keyword_deduction',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    table.reload('atjf');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        atjf_keyword_add(did){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-inline w334">
                            <textarea name="keywords" class="layui-textarea" placeholder="一行一个，可批量添加"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">单价</label>
                        <div class="layui-input-inline w334">
                        <input class="layui-input" type="number" name="money" required lay-verify="required" value="10.00">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">蓝v名称</label>
                        <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="name" required lay-verify="required" placeholder="请准确填写蓝v名称">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '新增',
                    area: ['640px'],
                    content: tpl(),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            data.field.did = did
                            yunu.post({
                                url: '/' + module_name + '/Douyin/atjf_keyword_add',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    table.reload('atjf');
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        atjf_keyword_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-inline w334">
                        <input class="layui-input layui-disabled" type="text" value="${data.keyword}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">单价</label>
                        <div class="layui-input-inline w334">
                        <input class="layui-input" type="number" name="money" required lay-verify="required" value="${data.money}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">蓝v名称</label>
                        <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="name" required lay-verify="required" value="${data.name}" placeholder="请准确填写蓝v名称">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '修改',
                    area: ['640px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function({ field }){
                            field.id = data.id
                            yunu.post({
                                url: '/' + module_name + '/Douyin/atjf_keyword_edit',
                                field: field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    obj.update(field)
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        douyin_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pd20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">获取线索</label>
                        <div class="layui-input-inline">
                            <input type="radio" name="getclue" value="1" title="拉取" ${data.getclue == 1 ? 'checked' : ''} required lay-verify="required">
                            <input type="radio" name="getclue" value="2" title="推送" ${data.getclue == 2 ? 'checked' : ''} required lay-verify="required">
                        </div>
                        <!-- <div class="layui-form-mid"><a href="https://eirdkdsihn.feishu.cn/wiki/wikcnlbqWzGlP3gbloiYfD4XPRb" target="_blank">点击查看使用说明</a></div> -->
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">剪辑模式</label>
                        <div class="layui-input-block">
                            <input type="radio" name="client_state" value="0" title="线上剪辑" ${data.client_state == 0 ? 'checked' : ''} required lay-verify="required">
                            <input type="radio" name="client_state" value="1" title="线下剪辑" ${data.client_state == 1 ? 'checked' : ''} required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">支持平台</label>
                        <div class="layui-input-block">
                            <input type="checkbox" name="auth_show[]" lay-skin="primary" value="1" title="抖音" ${data.auth_show.includes('1') ? 'checked' : ''}>
                            <input type="checkbox" name="auth_show[]" lay-skin="primary" value="2" title="快手" ${data.auth_show.includes('2') ? 'checked' : ''}>
                            <input type="checkbox" name="auth_show[]" lay-skin="primary" value="9" title="视频号" ${data.auth_show.includes('9') ? 'checked' : ''}>
                            <input type="checkbox" name="auth_show[]" lay-skin="primary" value="10" title="小红书" ${data.auth_show.includes('10') ? 'checked' : ''}>
                            <input type="checkbox" name="auth_show[]" lay-skin="primary" value="6" title="哔哩哔哩" ${data.auth_show.includes('6') ? 'checked' : ''}>
                            <input type="checkbox" name="auth_show[]" lay-skin="primary" value="5" title="今日头条" ${data.auth_show.includes('5') ? 'checked' : ''}>
                            <input type="checkbox" name="auth_show[]" lay-skin="primary" value="3" title="好看视频" ${data.auth_show.includes('3') ? 'checked' : ''}>
                        </div>
                    </div>
                    <!-- <div class="layui-form-item">
                        <label class="layui-form-label">每日发布上限</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="daily_release_max_count" value="${data.daily_release_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">可剪辑数量</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="video_task_max_count" value="${data.video_task_max_count}"  required lay-verify="required">
                        </div>
                    </div> -->
                    <div class="layui-form-item">
                        <label class="layui-form-label">短链接导出</label>
                        <div class="layui-input-block">
                            <input type="radio" name="short_url_export" value="0" title="仅视频链接" ${data.short_url_export == 0 ? 'checked' : ''} required lay-verify="required">
                            <input type="radio" name="short_url_export" value="1" title="视频+图文" ${data.short_url_export == 1 ? 'checked' : ''} required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">机构授权</label>
                        ${
                            data.pid === 0 ?
                            `
                            <div class="layui-input-block">
                                <input type="radio" name="mcn_auth" value="0" title="使用默认机构账号" ${data.mcn_auth == 0 ? 'checked' : ''} disabled>
                                <input type="radio" name="mcn_auth" value="2" title="使用上级机构账号" ${data.mcn_auth == 2 ? 'checked' : ''} required lay-verify="required">
                                <input type="radio" name="mcn_auth" value="1" title="使用自有机构账号" ${data.mcn_auth == 1 ? 'checked' : ''} required lay-verify="required">
                                <input type="radio" name="mcn_auth" value="3" title="使用客户机构账号" ${data.mcn_auth == 3 ? 'checked' : ''} required lay-verify="required">
                            </div>
                            `
                            :
                            `
                            <div class="layui-input-block">
                                <input type="radio" name="mcn_auth" value="0" title="使用默认机构账号" ${data.mcn_auth == 0 ? 'checked' : ''} disabled>
                                <input type="radio" name="mcn_auth" value="2" title="使用上级机构账号" ${data.mcn_auth == 2 ? 'checked' : ''} disabled>
                                <input type="radio" name="mcn_auth" value="1" title="使用自有机构账号" ${data.mcn_auth == 1 ? 'checked' : ''} required lay-verify="required">
                                <input type="radio" name="mcn_auth" value="3" title="使用客户机构账号" ${data.mcn_auth == 3 ? 'checked' : ''} required lay-verify="required">
                            </div>
                            `
                        }
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">代理IP</label>
                        ${
                            data.pid === 0 ?
                            `
                            <div class="layui-input-block">
                                <input type="radio" value="0" title="使用官方代理IP" ${data.ip_use == 0 ? 'checked' : ''} disabled>
                                <input type="radio" name="ip_use" value="1" title="使用上级代理IP" ${data.ip_use == 1 ? 'checked' : ''} required lay-verify="required">
                                <input type="radio" name="ip_use" value="2" title="使用自有代理IP" ${data.ip_use == 2 ? 'checked' : ''} required lay-verify="required">
                                <input type="radio" name="ip_use" value="3" title="使用终端代理IP" ${data.ip_use == 3 ? 'checked' : ''} required lay-verify="required">
                            </div>
                            `
                            :
                            `
                            <div class="layui-input-block">
                                <input type="radio" value="0" title="使用官方代理IP" ${data.ip_use == 0 ? 'checked' : ''} disabled>
                                <input type="radio" value="2" title="使用上级代理IP" ${data.ip_use == 2 ? 'checked' : ''} disabled>
                                <input type="radio" name="ip_use" value="1" title="使用自有代理IP" ${data.ip_use == 1 ? 'checked' : ''} required lay-verify="required">
                                <input type="radio" name="ip_use" value="3" title="使用终端代理IP" ${data.ip_use == 3 ? 'checked' : ''} required lay-verify="required">
                            </div>
                            `
                        }
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form', 'laydate', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let laydate = layui.laydate;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Douyin/edit_getclue',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            // obj.update(data.field);
                                            table.reload('table');
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        user_sub_editor(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    ${data.id ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">员工账号</label>
                        <div class="layui-input-inline w334">
                            ${
                                data.id
                                ?
                                `<input class="layui-input layui-disabled idis" type="text" disabled value="${data.uname ?? ''}">`
                                :
                                `<input class="layui-input" type="text" name="uname" required lay-verify="required">`
                            }
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">员工密码</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="password" name="upass" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">员工姓名</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="title" required lay-verify="required" value="${data.title ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">员工手机号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="tel" value="${data.tel ?? ''}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">员工职位</label>
                        <div class="layui-input-block">
                            <input type="radio" name="ulev" value="2" title="客服" ${data.ulev == 2 || !data.type ? 'checked' : ''}>
                            <input type="radio" name="ulev" value="4" title="销售" ${data.ulev == 4 ? 'checked' : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    area: ['566px'],
                    content: tpl(data),
                    success: function () {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/UserSub/editor',
                                field: data.field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info, {
                                        time: 1000,
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        crmrule(data, obj){
            let tpl = (data = {}) => `<form class="layui-form pt25 pb20" lay-filter="form">
                <div class="layui-form-item">
                    <label class="layui-form-label">所属客服</label>
                    <div class="layui-input-inline w334">
                        <select name="kf">
                            <option value="">空</option>
                            ${
                                data.map((v, i) => v.ulev === 2 ? `<option value="${v.id}">${v.title}</option>` : '').join('')
                            }
                        </select>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">所属销售</label>
                    <div class="layui-input-inline w334">
                        <select name="xs">
                            <option value="">空</option>
                            ${
                                data.map((v, i) => v.ulev === 4 ? `<option value="${v.id}">${v.title}</option>` : '').join('')
                            }
                        </select>
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确认</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                yunu.get({
                    url: '/' + module_name + '/Douyin/crmrule',
                    field: {id: data.id},
                    success: function (res) {
                        layer.open({
                            type: 1,
                            title: '所属管理',
                            area: ['631px', '60%'],
                            content: tpl(res.data),
                            success: function (layero, index) {
                                form.render();
                                form.on('submit(*)', function({field}){
                                    yunu.post({
                                        url: '/' + module_name + '/Douyin/crmrule',
                                        field: {
                                            id: data.id,
                                            oid: data.oid,
                                            ...field
                                        },
                                        success: function (res) {
                                            layer.close(index);
                                            yunu.msg(res.info, {
                                                end: function () {
                                                    window.location.reload();
                                                }
                                            });
                                        }
                                    });
                                    return false;
                                });
                            }
                        });
                    }
                });
            });
        },
        douyin_renew(data, obj){
            let tpl = (data = {}) => `<form class="layui-form pt25 pb20 pr50" lay-filter="form">
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐</label>
                    <div class="layui-input-block pr50">
                        ${
                            data.map((v, i) => `<div><input type="radio" name="package_id" value="${v.id}" title="${v.title} / ${v.price}元" alt="${v.price}" lay-filter="package" ></div>`).join('')
                        }
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确认</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                yunu.get({
                    url: '/' + module_name + '/Douyin/getpro',
                    success: function (res) {
                        layer.open({
                            type: 1,
                            title: '续费',
                            content: tpl(res.data),
                            success: function (layero, index) {
                                form.render();
                                form.on('submit(*)', function({field}){
                                    yunu.post({
                                        url: '/' + module_name + '/Douyin/renew',
                                        field: {
                                            id: data.id,
                                            oid: data.oid,
                                            ...field
                                        },
                                        success: function (res) {
                                            layer.close(index);
                                            yunu.msg(res.info, {
                                                end: function () {
                                                    window.location.reload();
                                                }
                                            });
                                        }
                                    });
                                    return false;
                                });
                            }
                        });
                    }
                });
            });
        },
        max_count_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pd20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">抖音企业号</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="douyin_enterprise_max_count" value="${data.douyin_enterprise_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">抖音员工号</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="douyin_employee_max_count" value="${data.douyin_employee_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">抖音个人号</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="douyin_max_count" value="${data.douyin_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">快手</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="kuaishou_max_count" value="${data.kuaishou_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">视频号</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="shipinhao_max_count" value="${data.shipinhao_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">小红书</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="xiaohongshu_max_count" value="${data.xiaohongshu_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">哔哩哔哩</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="bilibili_max_count" value="${data.bilibili_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">今日头条</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="toutiao_max_count" value="${data.toutiao_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">好看视频</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="number" name="haokan_max_count" value="${data.haokan_max_count}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form', 'laydate', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let laydate = layui.laydate;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Douyin/max_count_edit',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
    });

})(jQuery, window, document);
